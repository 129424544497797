<template>
  <div>
    <div class="bm-pending-section box" v-if="getPendingIncomingMeeting.length">
      {{ $t("friends.friends_invitations") }}
    </div>
    <b-m-meeting-list-item
      v-for="meeting in getPendingIncomingMeeting"
      :key="`bm-meeting-${meeting.id}`"
      :meeting="meeting"
      :current-user-uuid="currentUserUuid"
    ></b-m-meeting-list-item>
    <div class="bm-pending-section box" v-if="getPendingOutgoingMeetings.length">
      {{ $t("friends.friends_outgoing_invitations") }}
    </div>
    <b-m-meeting-list-item
      v-for="meeting in getPendingOutgoingMeetings"
      :key="`bm-meeting-${meeting.id}`"
      :meeting="meeting"
      :current-user-uuid="currentUserUuid"
    ></b-m-meeting-list-item>
    <div v-if="!getPendingIncomingMeeting.length && !getPendingOutgoingMeetings.length" class="placeholder-container">
      {{ $t("business_matching.business_matching_no_meetings_yet") }}
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import BMMeetingListItem from "./BMMeetingListItem";

export default {
  name: "BMPendingMeetings",

  components: {BMMeetingListItem},

  computed: {
    ...mapState("currentUser", {currentUserUuid: "userUuid"}),
    ...mapGetters("businessMatchingMeetings", ["getPendingOutgoingMeetings", "getPendingIncomingMeeting"]),
  },
};
</script>

<style scoped lang="scss">
.placeholder-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-size: 16px;
}

.bm-pending-section {
  padding: 4px 16px;
  font-style: normal;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: var(--primary-color-text);
}
</style>
